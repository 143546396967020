.sticker {
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
   .sticker > img{
      width: 400px;
      padding-top: 100px;
      margin-bottom: 300px;
      border-top: 2px solid black;
   }
}