.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 999;
  backdrop-filter: blur(10px);
  background-color: rgba(237, 231, 226, 0.7);
  transition: background-color 0.3s ease; 
}
.nav.scrolled {
  box-shadow: 0 4px 1px rgba(0, 0, 0, 0.1);
}
.nav__side {
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 25px;
}
.logo:hover,
.nav__home:hover,
.nav__about:hover,
.nav__contact:hover {
  cursor: pointer;
  color: rgba(238, 142, 86, 0.736);
}

.logo {
  width: 75px;
}

.logo:hover {
  animation: rotateAnimation 4s linear infinite; 
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media (max-width:600px) {
  .nav__side {
    gap: 10px;
  }
}