.home__container {
  height: 75vh;
  padding-top: 100px;
  padding-left: 50px;
  /* border-bottom: 2px solid black; */
  /* animation: fade-out linear;
  animation-timeline: view();
  animation-range: exit; */
  font-family: "Roboto Slab", serif;
}

/* @keyframes fade-out {
  25% { opacity: 0.5;}
  85%,
  100% {
    opacity: 0;
    scale: 1.5;
  }
} */

.img {
  width: 600px;
  scale: 0.8;
  opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range: entry 100px;
}

@keyframes fade-in {
  to {
    scale: 1;
    opacity: 1;
  }
}

.img-container {
  position: relative;
}
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease;
  opacity: 0; 
  visibility: hidden; 
}

.img-container:hover .modal {
  opacity: 1; 
  visibility: visible;
  transition-delay: 0.3s;
}


.modal__phone {
  height: 80%;
  top: 40px;
}

.modal__phone2 {
  /* width: 95%; */
  height: 83%;
  top: 25px;
  left: 8px;
}

.git__icon__text{
   text-align: center;
   color: white;
}


a {
  text-decoration: none;
  color: inherit;
}

.home__text {
  font-size: 6em;
}

.home__text__last {
  font-size: 3em;
  padding-top: 50px;
  color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  transform: translateY(50px);
  animation: moveup 1s linear forwards;
}

@keyframes moveup {
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
}
.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
}

.project__text {
  width: 600px;
  padding: 25px 0 25px 0;
  text-align: center;
  font-family: 'Roboto Slab', serif;
  font-size: small;
}

.title {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  font-weight: bold;
}

.title__text {
  position: relative;
  text-decoration: none;
  padding-top: 3.5px;
  font-size: large;
}

.title__text::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* Adjust the underline thickness as needed */
  background-color: rgba(238, 142, 86, 0.736); /* Set the underline color */
  transition: width 0.5s ease; /* Adjust the duration and easing as needed */
}

.title__text:hover::before {
  width: 100%;
}

.arrow {
  padding-left: 5px;
  transform: scale(1);
  color: rgb(128, 128, 128);
}

.keepsake__text,
.card__text {
  padding-top: 35px;
}

.keepsake__arrow,
.card__arrow {
  padding-top: 31px;
}

.project__icon__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-bottom: 10px;
  /* font-family: 'Roboto Slab', serif; */
}

.project__icon {
  font-size: 10px;
  border: 1px solid rgba(0, 0, 0, 0.475);
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.475);
  width: fit-content;
  padding: 3px;
}

.see-more {
  color: rgba(78, 75, 75, 0.759);
}

.see-more:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .home__container {
    margin-top: 100px;
    border-bottom: none;
    height: 65vh;
  }
  .home__text {
    font-size: 2em;
  }
  @keyframes fade-out {
    65%,
    100% {
      opacity: 0;
      scale: 2;
    }
  }

  .home__text__last {
    font-size: larger;
  }

  .img {
    width: 300px;
  }
  .project__text {
    width: 300px;
  }

  .modal__phone {
    top: 20px;
  }
  .modal__phone2 {
    top: 10px;
    left: 5px;
  }
}
